.navbar {
  background-color: #012935;
  color: white;
}

.navbar-nav {
  margin-left: auto;
  margin-right: 0 !important;

  .nav-link {
    font-family: Poppins, sans-serif;
    color: white !important;
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
}

.nav__signup {
  color: black !important;
  display: block;
  text-decoration: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
  display: flex;

  span {
    border-radius: 50rem;
    background-color: white;
    padding: 0.5rem 1rem;
  }
}

.navbar-toggler {
  filter: invert(1) !important;

  &:focus {
    box-shadow: 0 0 0 0.1rem;
  }
}
.main {
  position: relative;
  display: flex;
  align-items: center;
  &:hover .sub_div {
    position: absolute;
    top: 120%;
    visibility: visible;
    display: block;
  }
  .sub_div {
    position: absolute;
    top: 180%;
    left: -100%;
    visibility: hidden;
    width: 600px;
    display: none;
    border-radius: 14px;
    transition: visibility 0.3s ease-in-out;
    background: #ffffff;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    &::before {
      content: "";
      width: 25px;
      height: 25px;
      transform: rotate(45deg);
      background-color: #ffffff;
      position: absolute;
      top: 0%;
      left: 25%;
      z-index: 999;
    }
    > .img {
      position: absolute;
      top: 4%;
      right: 4%;
    }
    .data {
      padding: 0px 20px;
      align-items: center;
      span {
        color: #012935;
      }
    }
    > div {
      position: relative;
    }
    .bottom_div {
      background: #f8f5ff;
      padding: 25px 35px;
      border-radius: 0% 0% 14px 14px;

      > h1 {
        font-family: Poppins, sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 25px;
        color: #222222;
      }
      p {
        font-family: Poppins, sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 160%;
        letter-spacing: 0.02em;
        color: #5e5e5e;
      }
    }
  }
}
@media only screen and (max-width: 1600px) {
  .sub_div {
    width: 425px !important;
    &::before {
      left: 30% !important;
    }
  }
}
@media only screen and (max-width: 1200px) {
  .sub_div {
    width: 390px !important;
    &::before {
      left: 30% !important;
    }
  }
}
@media only screen and (max-width: 990px) {
  .sub_div {
    left: 0 !important;
    width: 100% !important;
    &::before {
      left: 5% !important;
    }
  }
}
@media only screen and (max-width: 567px) {
  .bottom_div {
    background: #f8f5ff;
    padding: 25px 35px;
    border-radius: 0% 0% 14px 14px;

    > h1 {
      font-family: Poppins, sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 18px !important;
      color: #222222;
    }
    p {
      font-family: Poppins, sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 14px !important;
      line-height: 160%;
      letter-spacing: 0.02em;
      color: #5e5e5e;
    }
  }
}
