.a-2 {
  position: relative;
  .left_img_2 {
    position: absolute;
    left: 0%;
    top: 5%;
    z-index: -1;
  }
  h1 {
    font-family: Lato, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 38px;
    line-height: 120%;
    text-transform: capitalize;
    color: #222222;
  }
  p {
    font-family: Poppins, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 160%;
    letter-spacing: 0.02em;
    color: #5e5e5e;
    margin-top: 23px;
  }
}
.img_div_1 {
  position: relative;
  .back_img {
    position: absolute;
    bottom: -55px;
    right: -55px;
    z-index: -1;
  }
}
.a-3 {
  padding: 80px 0;
  position: relative;
  .card-row {
    display: flex;
    // flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .left_img_3 {
    position: absolute;
    top: 25%;
    right: 0%;
    z-index: -1;
  }
  h1 {
    font-family: Lato, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 120%;
    text-align: center;
    text-transform: capitalize;
    color: #222222;
  }
  .icons {
    display: flex;
    .icon {
      background: #ffffff;
      width: 30px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 5px;
      border-radius: 50%;
      font-size: 22px;
    }
  }
  .box1 {
    background: rgba(1, 41, 53, 0.96);
    border-radius: 10px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.02);
    border-radius: 10px;
    // padding: 0px 10px;
    // height: 420px;
    // margin: 8px 0px;
    height: 100%;
    width: 100%;
    position: absolute;
    // box-shadow: rgba(1, 41, 53, 0.5) 0px 7px 29px 0px;
    opacity: 0;
    transition: 0.2s ease-in;
    > h5 {
      color: #ffffff;
      margin: 0%;
    }
    > p {
      color: rgba(255, 255, 255, 0.8);
    }
    > a {
      text-decoration: underline;
      &:hover {
        cursor: pointer;
      }
    }
    &:hover {
      opacity: 1;
    }
  }
  .box {
    background: #ffffff;
    border: 1px solid #eaeaea;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.02);
    border-radius: 10px;
    height: 420px;
    margin: 8px 0px;
    position: relative;
  }
  h5 {
    font-family: Poppins, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 25px;
    text-align: center;
    color: #222222;
    margin: 35px 0% 8px 0%;
  }

  p {
    font-family: Poppins, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 160%;
    letter-spacing: 0.02em;
    color: #5e5e5e;
    text-align: center;
    margin: 10px 0px;
  }
  a {
    font-family: Poppins, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 160%;
    letter-spacing: 0.02em;
    color: #012935;
    text-align: center;
    font-weight: 700;
    text-decoration: none;
  }
  img {
    border-radius: 50%;
  }

  .popup {
    width: 80%;
    padding: 35px 0px;
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
    background-color: white;
    padding-right: 2%;
    padding-left: 2%;
    filter: drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.15));
    border-radius: 10px;

    .close-icon {
      width: 30px;
      height: 30px;
      background-color: rgba(242, 238, 254, 255);
      border-radius: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #5e5e5e;
      font-size: 10px;
      position: absolute;
      top: 20px;
      right: 20px;
      &:hover {
        cursor: pointer;
      }
    }
    .row {
      --bs-gutter-x: 0rem;
    }
    .icon {
      background-color: rgba(242, 238, 254, 255);
      padding: 5px;
      margin-bottom: 0px;
    }
    .c-post {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 150.6%;
      /* identical to box height, or 24px */

      color: #012935;
    }
    .c-pic {
      border-radius: 8px;
      width: 350px;
    }
    .content {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 160%;
      /* or 22px */

      display: flex;
      align-items: center;
      letter-spacing: 0.02em;

      color: #5e5e5e;
    }
    .c-info {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
    }
    .email,
    .phone {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 160%;
      /* or 29px */

      display: flex;
      align-items: center;
      letter-spacing: 0.02em;

      color: #222222;
    }
  }
}
.modal-content {
  border-radius: 1.3rem;
  padding: 40px 35px;
}
.modal {
  padding: 0% !important;
}
.a-4 {
  .main_div {
    .a {
      position: relative;
      .close_btn {
        position: absolute;
        width: 65px;
        height: 41px;
        right: -1%;
        top: -4%;
      }
    }
  }
  .icons {
    display: flex;
    justify-content: center;
    margin-top: 19px;
    .icon {
      background: rgba(120, 84, 247, 0.1);
      box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.1);
      border-radius: 100px;
      width: 45px;
      height: 45px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 7px;
      border-radius: 50%;
      font-size: 22px;
    }
  }
  .img_main {
    border-radius: 14px;
    width: 100%;
    height: auto;
  }
  h1 {
    font-family: Poppins, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 38px;
    line-height: 120%;
    text-transform: capitalize;
    color: #222222;
  }
  span {
    font-family: Poppins, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    color: #012935;
  }
  p {
    font-family: Poppins, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 160%;
    letter-spacing: 0.02em;
    color: #5e5e5e;
  }
  .Address {
    font-family: Poppins, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    display: flex;
    align-items: center;
    letter-spacing: 0.02em;
    color: #222222;
  }
  .Address_2 {
    font-family: Poppins, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 160%;
    display: flex;
    align-items: center;
    letter-spacing: 0.02em;

    color: #5e5e5e;
  }
}
.a-5 {
  padding: 40px 0;
  .box {
    background: #ffffff;
    border: 1px solid #eaeaea;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.02);
    border-radius: 10px;
    height: 420px;
    margin: 6px 0px;
  }
  h5 {
    font-family: Poppins, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 25px;
    text-align: center;
    color: #222222;
    margin: 35px 0% 8px 0%;
  }

  p {
    font-family: Poppins, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 160%;
    letter-spacing: 0.02em;
    color: #5e5e5e;
    text-align: center;
    margin: 10px 0px;
  }
  a {
    font-family: Poppins, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 160%;
    letter-spacing: 0.02em;
    color: #012935;
    text-align: center;
    font-weight: 700;
    text-decoration: none;
  }
  .img {
    border-radius: 50%;
  }
}
.a-6 {
  padding: 80px 0;
  position: relative;
  .left_img_2 {
    position: absolute;
    left: 0%;
    top: -15%;
    z-index: -1;
  }
  .left_img_bottom {
    justify-content: center;
    display: flex;
    flex-direction: column;
    padding: 50px 0px;
  }
  .left_img_bottom2 {
    margin-top: 9%;
    display: flex;
    flex-direction: column;
    padding: 0px 20px;
  }
  h1 {
    font-family: Poppins, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 38px;
    line-height: 120%;
    text-transform: capitalize;
    color: #222222;
    text-align: center;
  }
  h5 {
    font-family: Lato, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    text-align: center;
    color: #000000;
    text-align: left;
  }
  p {
    font-family: Poppins, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 160%;
    letter-spacing: 0.02em;
    color: #5e5e5e;
    text-align: center;
  }
  span {
    font-family: Poppins, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 160%;
    letter-spacing: 0.02em;
    color: #5e5e5e;
    text-align: left;
  }
  .img_div {
    position: relative;

    .large-img {
      width: 80%;
      height: 280px;
      // height: 100px;
      // padding-right: 10%;
      border-radius: 50px 0 50px 50px;
      overflow: hidden;
    }

    .c-img {
      width: 100%;
    }
    .back_img {
      position: absolute;
      bottom: 10px;
      right: 10px;
      z-index: -1;
    }
    .right_img_ {
      position: absolute;
      top: 0px;
      right: 0px;
      z-index: -1;
    }
  }
  .right_img_bottom {
    display: flex;
    justify-content: center;
    flex-direction: column;

    .bottom_img {
      position: relative;
      background: url("../../assets/img/Ellipse\ 93.png");
      background-repeat: no-repeat;
      background-position: center;
      height: 100%;
      width: 100%;

      // .back_img2 {
      //   position: absolute;
      //   left: 11%;
      //   top: 30%;
      //   z-index: -1;
      // transform: translate(-100px, -100px);
      // }
      .c1 {
        position: absolute;
        top: 28%;
        bottom: 20%;
        // left: 70%;
        right: 24%;
      }
      .c2 {
        position: absolute;
        top: 55%;
        left: 18%;
      }
      .c3 {
        position: absolute;
        top: 40%;
        right: 0%;
      }

      .c4 {
        position: absolute;
        top: 15%;
        right: 15%;
      }
      .c5 {
        position: absolute;
        bottom: 18%;
        right: 13%;
      }

      .c6 {
        position: absolute;
        top: 0%;
        right: 43%;
      }
      .c7 {
        position: absolute;
        bottom: 3%;
        right: 38%;
      }
      .c8 {
        position: absolute;
        top: 20%;
        left: 14%;
      }
    }
  }
}
@media only screen and (max-width: 992px) {
  .main_div {
    background: #ffffff;
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    > .a {
      position: relative;
    }
    .close_btn {
      position: absolute;
      width: 55px !important;
      height: 31px !important;
      right: -2% !important;
      top: -3.5% !important;
    }
  }
  .modal-body {
    padding: 0%;
  }
  .main_div {
    padding: 50px 20px;
  }
  .modal-content {
    border-radius: 1.3rem;
    padding: 0%;
  }
  .a-6 {
    .right_img_bottom {
      height: 400px;
      width: 500px;
    }
  }
}
@media only screen and (max-width: 768px) {
  .a-2,
  .a-3 {
    h1 {
      font-size: 24px;
    }
    p {
      font-size: 14px;
    }
  }
  .left_img_bottom2 {
    margin: 0%;
  }
}
@media only screen and (max-width: 598px) {
  .a-2 {
    h1 {
      font-size: 24px;
    }
    p {
      font-size: 14px;
    }
  }
  .back_img {
    display: none;
  }
}
