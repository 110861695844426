.footer__wrapper {
  background-color: #ece6ff;
  padding: 80px 0;
}

.footer__colum-wrapper {
  display: flex;
  flex-direction: column;
}

.footer-description {
  color: #5e5e5e;
  margin-top: 25px;
  font-size: 17px;
  font-family: "poppins";
}

.footer__social-wrappr {
  display: flex;
  flex-direction: row;
  margin-top: 25px;
  grid-gap: 15px;
}

.footer__social-icon {
  background-color: rgba(120, 84, 247, 0.1);
  color: #012935;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 22px;

  &:hover {
    color: #80c93f;
  }
}

.footer__colum-heading {
  color: black;
  font-family: "lato";
  font-weight: 600;
  font-size: 22px;
}

.footer__colum-link {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  grid-row-gap: 15px;
  margin-top: 25px;

  li {
    list-style-type: none;

    svg {
      color: #012935;
      margin-right: 10px;
      font-size: 22px;
    }

    a {
      color: #5e5e5e;
      text-decoration: none;
    }
  }
}

.footer__copyright-wrapper {
  background-color: #012935;
}

.footer__copyright {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  padding: 25px 0;
}

.copyright-text {
  color: white;
  font-family: "poppins";
  font-weight: 300;
  text-align: center;
}

.footer-link {
  color: white;
  font-family: "poppins";
  font-weight: 300;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  a {
    color: white;
    text-decoration: none;
    margin: 0 10px;
  }
}

@media screen and (max-width: 992px) {
  .footer__copyright {
    flex-direction: column;
  }

  .footer-link {
    margin-top: 20px;
  }
}
