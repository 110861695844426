@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,700;1,100;1,300;1,400;1,700;1,700&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,700&display=swap");

body {
  font-family: Poppins, sans-serif;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  overflow-x: hidden;
}

.right_img {
  position: absolute;
  left: 0%;
  top: 20%;
}

.left_img {
  position: absolute;
  right: 0%;
  top: 50%;
}

.main_ {
  border-bottom: 1px solid #263238;
}

.right_div {
  h1 {
    font-family: Lato, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 38px;
    line-height: 120%;
    text-transform: capitalize;
    color: #222222;
  }

  p {
    font-family: Poppins, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 160%;
    letter-spacing: 0.02em;
    color: #5e5e5e;
    margin-top: 23px;
  }
}

.s-2 {
  padding: 65px 0;
  position: relative;

  .right_img {
    position: absolute;
    left: 3%;
    top: 4%;
  }
  .right_img_2 {
    position: absolute;
    right: 10%;
    top: 40%;
    z-index: -1;
  }
  .right_img_2_2 {
    position: absolute;
    right: 0%;
    top: 60%;
    z-index: -1;
  }
  .left_img {
    position: absolute;
    right: 3%;
    top: 0%;
  }

  .left_img_2 {
    position: absolute;
    left: 0%;
    top: 5%;
    z-index: -1;
  }

  h1 {
    font-family: Lato, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 120%;
    text-align: center;
    text-transform: capitalize;
    color: #222222;
  }

  p {
    font-family: Poppins, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 160%;
    letter-spacing: 0.02em;
    color: #5e5e5e;
    text-align: center;
    margin-top: 23px;
  }

  .main_box {
    margin: auto;
    background: #ffffff !important;
    border: 1px solid #eaeaea;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.02);
    border-radius: 12px;
    padding: 25px;
    margin-top: 50px;

    // height: 400px;
    .input_main {
      label {
        font-family: Poppins, sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 33px;
        color: #222222;
        padding-left: 6px;
      }

      input {
        background: #f8f5ff;
        border: 1.04444px solid #eaeaea;
        border-radius: 10.4444px;
        outline: none;
        padding: 14px 16px;
        font-size: 14px;
      }

      textarea {
        background: #f8f5ff;
        border: 1.04444px solid #eaeaea;
        border-radius: 10.4444px;
        outline: none;
        padding: 8px 14px;
        font-size: 14px;
      }
    }

    .left_said {
      width: 85%;
      margin: auto;

      h5 {
        font-family: Poppins, sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 33px;
        color: #222222;
        margin-left: 15px;
      }

      .icon_main {
        display: flex;
        justify-content: center;
        align-items: center;

        .icon {
          width: 50px;
          height: 50px;
          display: flex;
          justify-content: center;
          align-items: center;
          background: #eaeaea;
          border-radius: 50%;
          color: #012935;
          font-size: 24px;
        }
      }

      .icon_main_text {
        width: 80%;

        .icon_text {
          font-family: Poppins, sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 160%;
          letter-spacing: 0.02em;
          color: #5e5e5e;
          text-align: left;
          margin: 0;
          margin-left: 14px;
        }
      }
    }

    button {
      background: #012935;
      font-size: 14px;
      color: #ffffff;
      padding: 10px 48px;
      margin-top: 10px;
      border: none;
      outline: none;
      border-radius: 100px;
      margin-top: 50px;
    }
  }
}
.s-3 {
  margin: 65px 0;
  position: relative;
  .left_img_3_1 {
    position: absolute;
    top: 3.4%;
    right: 4%;
    z-index: -1;
  }
  .right_img_3_3 {
    position: absolute;
    left: 2%;
    top: 30%;
    z-index: -1;
  }
  .right_img_3_4 {
    position: absolute;
    left: 0%;
    top: 60%;
    z-index: -1;
  }
  .left_img_3_2 {
    position: absolute;
    top: 87%;
    right: 0;
    z-index: -1;
  }
  .left_img_3 {
    position: absolute;
    top: 10%;
    right: 0;
    z-index: -1;
  }
  .right_img_3_2 {
    position: absolute;
    top: 95%;
    left: 0;
    z-index: -1;
  }
  h1 {
    font-family: Lato, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 120%;
    text-align: center;
    text-transform: capitalize;
    color: #222222;
  }
  p {
    font-family: Poppins, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 160%;
    letter-spacing: 0.02em;
    color: #5e5e5e;
    text-align: center;
    margin-top: 23px;
  }
}
@media only screen and (max-width: 768px) {
  .main_ {
    .right_div {
      h1 {
        font-size: 24px;
      }
      p {
        font-size: 14px;
      }
    }
  }
  .s-2 {
    h1 {
      font-size: 24px;
    }
    p {
      font-size: 14px;
    }
    .main_box {
      .left_said {
        width: 100%;
      }
    }
  }
  .s-3 {
    h1 {
      font-size: 24px;
    }
    p {
      font-size: 14px;
    }
  }
}
@media only screen and (max-width: 556px) {
  .s-2 .main_box .left_said h5 {
    margin-left: 0px;
  }
  .s-2 .main_box {
    padding: 10px;
  }
}
